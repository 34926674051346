import { useLocation } from 'react-router-dom';

import { useRequest } from 'ahooks';

import { admin } from '@saturn/api';

const featuresLocal: Record<string, boolean> = {
  // TODO: remove in the scope of AADTT-21303
  'gb-chubb-plan-selection-new-design': true,
};

export default function useFeatures() {
  const location = useLocation();
  const { data: featuresBE = {} } = useRequest(() => admin.loadFeatures(), {
    refreshDeps: [location],
  });
  return process.env.NODE_ENV === 'development' ? { ...featuresBE, ...featuresLocal } : featuresBE;
}
