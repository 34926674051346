/* eslint-disable sonarjs/no-identical-functions */
import type { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useRequest } from 'ahooks';
import dayjs from 'dayjs';

import { admin, getLocationTimezone } from '@saturn/api';
import { PromocodeDto, PromocodeV3Dto } from '@saturn/api/src/models/dto/PromocodeDto';
import { Icon, Popconfirm, Spin, Switch, Table, notification } from '@saturn/uikit';

import { PageHeader } from 'shared/components';
import { useAdminLocation } from 'shared/utils';

import styles from './styles.module.scss';

const PromocodesPage = () => {
  const location = useLocation();
  const { data: features = {}, loading } = useRequest(() => admin.loadFeatures(), {
    refreshDeps: [location],
  });
  const arePromocodesV3 = features['cms-promocode-v3-enabled'];

  if (loading) {
    return null;
  }

  if (arePromocodesV3) {
    return <PromocodesPageV3 />;
  }
  return <OldPromocodesPage />;
};

const PromocodesPageV3: FC = () => {
  const { adminLocation } = useAdminLocation();
  const location = useLocation();
  const locationTimezone = getLocationTimezone(adminLocation.id);

  const {
    data: locationPromocodes = [],
    loading,
    refresh,
  } = useRequest(
    async () => {
      return await admin.getPromocodesV3(adminLocation.id);
    },
    {
      refreshDeps: [adminLocation.id],
    },
  );

  const { runAsync: enablePromocode } = useRequest(admin.enablePromocodeV3, {
    manual: true,
    onSuccess: () => {
      refresh();
      notification.success({
        message: 'Promocode has been activated successfully.',
        duration: 5,
      });
    },
  });
  const { runAsync: disablePromocode } = useRequest(admin.disablePromocodeV3, {
    manual: true,
    onSuccess: () => {
      refresh();
      notification.success({
        message: 'Promocode has been deactivated successfully.',
        duration: 5,
      });
    },
  });
  const { run: deletePromocode, loading: deleting } = useRequest(admin.deletePromocodeV3, {
    manual: true,
    onSuccess: () => {
      refresh();
      notification.success({
        message: 'Promocode has been successfully deleted',
        duration: 5,
      });
    },
    onError: () => {
      notification.error({
        message: 'Error while deleting promocode',
        duration: 5,
      });
    },
  });

  return (
    <main>
      <PageHeader title="Promocodes" subTitle="Edit Promocodes" />
      <div className={styles.buttonContainer}>
        <Link type="primary" className="ant-btn ant-btn-primary" to="create">
          <span>Create Promo</span>
        </Link>
      </div>
      <Spin spinning={loading || deleting} indicator={<Icon name="sync-outline" />}>
        <Table
          rowKey={record => record.id || ''}
          columns={[
            {
              title: 'Created at',
              dataIndex: 'createdAt',
              render: (text: string) => dayjs(text).format('MMM D, YYYY'),
            },
            {
              title: 'Start date',
              dataIndex: 'startDate',
              render: (text: string | null) =>
                text ? dayjs(text).utcOffset(locationTimezone).format('MMM D, YYYY') : 'N/A',
            },
            {
              title: 'End date',
              dataIndex: 'endDate',
              render: (text: string | null) =>
                text ? dayjs(text).utcOffset(locationTimezone).format('MMM D, YYYY') : 'N/A',
            },
            {
              title: 'Product',
              dataIndex: 'productId',
            },
            {
              title: 'Edit',
              dataIndex: 'id',
              render: (id?: string) => {
                const path = `${location.pathname}/${id}`.replaceAll(/\/{2,}/gi, '/');

                return id ? (
                  <Link data-key="action-edit" to={path}>
                    <Icon name="edit-2-outline" />
                  </Link>
                ) : null;
              },
              width: 100,
            },
            {
              title: 'Active Campaign',
              dataIndex: 'active',
              width: 150,
              render: (visible: boolean, record: PromocodeV3Dto) => {
                const { id } = record;
                return id ? (
                  <Switch
                    checkedChildren="yes"
                    unCheckedChildren="no"
                    checked={visible}
                    data-key="action-active"
                    onChange={() => (visible ? disablePromocode(id) : enablePromocode(id))}
                  />
                ) : null;
              },
            },

            {
              title: 'Delete',
              dataIndex: 'id',
              width: 80,
              render: function deleteUserAction(id?: string) {
                return id ? (
                  <Popconfirm
                    placement="bottomRight"
                    title="Are you sure you want to delete this promo?"
                    onConfirm={() => deletePromocode(id)}
                    okText="Confirm"
                    cancelText="Cancel"
                  >
                    <Icon data-key="action-delete" color="red" size={20} name="close-circle-outlined" />
                  </Popconfirm>
                ) : null;
              },
            },
          ]}
          dataSource={locationPromocodes}
          pagination={false}
        />
      </Spin>
    </main>
  );
};

const OldPromocodesPage: FC = () => {
  const { adminLocation } = useAdminLocation();
  const location = useLocation();

  const {
    data: locationPromocodes = [],
    loading,
    refresh,
  } = useRequest(
    async () => {
      const res = await admin.getPromocodes(adminLocation.id);
      return res.promocodes;
    },
    {
      refreshDeps: [adminLocation.id],
    },
  );

  const { runAsync: enablePromocode } = useRequest(admin.enablePromocode, {
    manual: true,
    onSuccess: () => {
      refresh();
      notification.success({
        message: 'Promocode has been activated successfully.',
        duration: 5,
      });
    },
  });
  const { runAsync: disablePromocode } = useRequest(admin.disablePromocode, {
    manual: true,
    onSuccess: () => {
      refresh();
      notification.success({
        message: 'Promocode has been deactivated successfully.',
        duration: 5,
      });
    },
  });
  const { run: deletePromocode, loading: deleting } = useRequest(admin.deletePromocode, {
    manual: true,
    onSuccess: () => {
      refresh();
      notification.success({
        message: 'Promocode has been successfully deleted',
        duration: 5,
      });
    },
    onError: () => {
      notification.error({
        message: 'Error while deleting promocode',
        duration: 5,
      });
    },
  });

  return (
    <main>
      <PageHeader title="Promocodes" subTitle="Edit Promocodes" />
      <div className={styles.buttonContainer}>
        <Link type="primary" className="ant-btn ant-btn-primary" to="create">
          <span>Create Promo</span>
        </Link>
      </div>
      <Spin spinning={loading || deleting} indicator={<Icon name="sync-outline" />}>
        <Table
          rowKey={record => record.id || ''}
          columns={[
            {
              title: 'Promocode',
              dataIndex: 'promocodeValue',
            },
            {
              title: 'Created at',
              dataIndex: 'createdAt',
              render: (text: string) => dayjs(text).format('MMM D, YYYY'),
            },
            {
              title: 'Expires at',
              dataIndex: 'expiredDate',
              render: (text: string | null) => (text ? dayjs(text).format('MMM D, YYYY') : 'N/A'),
            },
            {
              title: 'Product',
              dataIndex: 'productId',
            },
            {
              title: 'Edit',
              dataIndex: 'id',
              render: (id?: string) => {
                const path = `${location.pathname}/${id}`.replaceAll(/\/{2,}/gi, '/');

                return id ? (
                  <Link data-key="action-edit" to={path}>
                    <Icon name="edit-2-outline" />
                  </Link>
                ) : null;
              },
              width: 100,
            },
            {
              title: 'Active',
              dataIndex: 'isActive',
              width: 150,
              render: (visible: boolean, record: PromocodeDto) => {
                const { id } = record;
                return id ? (
                  <Switch
                    checkedChildren="yes"
                    unCheckedChildren="no"
                    checked={visible}
                    data-key="action-active"
                    onChange={() => (visible ? disablePromocode(id) : enablePromocode(id))}
                  />
                ) : null;
              },
            },

            {
              title: 'Delete',
              dataIndex: 'id',
              width: 80,
              render: function deleteUserAction(id?: string) {
                return id ? (
                  <Popconfirm
                    placement="bottomRight"
                    title="Are you sure you want to delete this promo?"
                    onConfirm={() => deletePromocode(id)}
                    okText="Confirm"
                    cancelText="Cancel"
                  >
                    <Icon data-key="action-delete" color="red" size={20} name="close-circle-outlined" />
                  </Popconfirm>
                ) : null;
              },
            },
          ]}
          dataSource={locationPromocodes}
          pagination={false}
        />
      </Spin>
    </main>
  );
};

export default PromocodesPage;
