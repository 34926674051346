// Mapping is required because process.env.{envKey} is replaced with variable value during buildtime for Next.js. So, it's incorrect to use process.env[envKey]
const processEnvVariableMapping: { [key: string]: string | undefined } = {
  BUILD_TAG: process.env.BUILD_TAG,
  DEPLOY_TIMESTAMP: process.env.DEPLOY_TIMESTAMP,
  COMMIT_HASH: process.env.COMMIT_HASH,
  API_SUFFIX: process.env.API_SUFFIX,
  API_ENDPOINT: process.env.API_ENDPOINT,
  ANALYTICS_ENABLED: process.env.ANALYTICS_ENABLED,
  VERISK_SCRIPT: process.env.VERISK_SCRIPT,
  VERISK_STYLE: process.env.VERISK_STYLE,
  SCRIPT_SUPPLIER_HEADER_SCRIPT_V606: process.env.SCRIPT_SUPPLIER_HEADER_SCRIPT_V606,
  VIVOCHA_ACCOUNT_ID: process.env.VIVOCHA_ACCOUNT_ID,
  NEXT_PUBLIC_API_LOGGING_ENABLED: process.env.NEXT_PUBLIC_API_LOGGING_ENABLED,
  NEXTJS_HOSTNAME: process.env.NEXTJS_HOSTNAME,
  PORT: process.env.PORT,
  CDN_PATH: process.env.CDN_PATH,
  NEXT_PUBLIC_STAGING_ENV: process.env.NEXT_PUBLIC_STAGING_ENV,
  NEXT_PUBLIC_ABSOLUTE_API: process.env.NEXT_PUBLIC_ABSOLUTE_API,
  NEXT_PUBLIC_IMAGES_OPTIMIZED: process.env.NEXT_PUBLIC_IMAGES_OPTIMIZED,
  NEXT_PUBLIC_COLLECT_METRICS: process.env.NEXT_PUBLIC_COLLECT_METRICS,
  NEXT_PUBLIC_ROUTER_PATCHING_SLUG: process.env.NEXT_PUBLIC_ROUTER_PATCHING_SLUG,
  PROMETHEUS_PUSHGATEWAY: process.env.PROMETHEUS_PUSHGATEWAY,
  PUSHGATEWAY_APP_HOSTNAME: process.env.PUSHGATEWAY_APP_HOSTNAME,
  REACT_APP_HEADER_URL: process.env.REACT_APP_HEADER_URL,
  REACT_APP_FOOTER_URL: process.env.REACT_APP_FOOTER_URL,
  APPLICATION_ID_SNIPPER: process.env.APPLICATION_ID_SNIPPER,
  IDENTITY_POOL_ID: process.env.IDENTITY_POOL_ID,
  VERISK_ROOT_URL: process.env.VERISK_ROOT_URL,
  VERISK_USERNAME: process.env.VERISK_USERNAME,
  NEXT_PUBLIC_IS_MGM_STORED_IN_DIGITAL_DATA: process.env.NEXT_PUBLIC_IS_MGM_STORED_IN_DIGITAL_DATA,
  NEXT_PUBLIC_IS_PLAN_SELECTION_V2: process.env.NEXT_PUBLIC_IS_PLAN_SELECTION_V2,
};

declare global {
  interface Window {
    envVariables?: { [key: string]: string | undefined };
  }
}

// This method returns:
// Client-side: runtime environment variable value if it was set as a window object field. If not - buildtime process.env[envVariableName]
// Server-side: runtime environment variable global.process.env[envVariableName]
export const getEnvVariable = (envVariableName: string): string | undefined => {
  const buildVariable = processEnvVariableMapping[envVariableName];
  if (typeof window !== 'undefined') {
    if (window?.envVariables)
      return (
        window?.envVariables[envVariableName] ??
        // Fallback to build variable if window.envVariables is initialized, but no variable was found
        buildVariable
      );
    return (
      buildVariable ??
      // Fallback to process.env[envKey] for non-Next.js apps
      process.env[envVariableName]
    );
  }
  return global.process.env[envVariableName];
};

// This method is required to set runtime environment variables to the window object
export const initializeEnvVariables = (envVariables: { [key: string]: string | undefined }) => {
  if (typeof window !== 'undefined') {
    window.envVariables = envVariables;
  } else {
    throw new Error('This method can be called on the client-side only!');
  }
};
